@import '../helpers/variables';

.v-application--is-ltr .roles-checkbox .v-messages {
  display: none !important;
}
.v-input__control {
  position: relative;
}
.v-application .success--text{
  color: white !important;
}
.mdi-checkbox-blank-outline::before{
  color: rgba(0,0,0, 0.2) !important;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
  margin-bottom: 15px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  position: absolute;
  width: 100%;
  bottom: -2px;
  margin: 0;
}

.titulo-cadastro {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.7em;
  text-transform: uppercase;
  font-weight: bold;
}

.roles {
  padding-bottom: 0px;
  padding-top: 5px;
  font-size: $fontCadastroUsuario;
  .v-card{
    width: 100%;
    padding: 10px;
  }
  &-error{
    border: 1px solid red !important;
  }
  &-title {
    padding-left: 0 !important;
  }
  &-borda{
    font-size: $fontCadastroUsuario;
    font-weight: 600;
    border-radius: 2px;
    margin-bottom: 5px;
    border: solid 1px rgba(68,138,255,0.5);
    &:hover{
      border: solid 1px rgba(68,138,255, 1);
      background: #fff;
    }
  }
  &-active{
    background: #448aff;
    color: white;
    &:hover{
      background: #448aff;
    }
  }
  label{
    padding-left: 20px;
    display: inline-block;
  }
  &-name{
    padding-left: 20px;
  }
  &-checkbox {
    padding: 5px;
    float: right;
  }
  &-check-subTitle {
    display: inline-block;
  }
  &-icon {
    float: right;
    display: flex;
    height: 100%;
    position: relative;
    right: 4px;
    top: -1px;
    color: red;
    &-active{
      color: #448aff;
    }
  }
  &-div{
    padding-bottom: 0;
    padding-top: 0;
  }
  &-sub {
    padding: 10px;
  }
  &-sub-itens{
    background: white;
    color: #448aff;
  }
  &-lista {
    display: inline-flex;
    align-items: center;
    width: 100%;
    &:hover{
      background: white;
    }
    &-check-sub {
      float: right;
    }
    &-default {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .v-input--selection-controls{
    margin: 0;
  }
  .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot{
    margin: 0
  }
}