@import '../helpers/shareds';
@import './modal';

.v-dialog {
  > .v-card {
    padding: 5px 0 20px;
    .modal-cadastro {
      &-titulo {
        padding: 0 36px 5px;
        font-size: em(16px);
        color: $colorPrimary;
        text-transform: uppercase;
        font-weight: bold;
        &.fechado {
          color: $colorWarning;
          padding-bottom: 0;
        }

        @media (max-width: 360px) {
          font-size: .8em;
        }
      }

      &-form {
        padding: 0 36px;
        &-col {
          &-imput {
            padding: 0 12px;

            &.select-count {
              color: #F00;
              .v-select__selections {
                padding-right: 50px;
          
                .caption {
                  position: absolute;
                  right: 30px;
                }
              }
            }
          }
        }
      }

      &-footer {
        padding: 20px 36px 30px;
      }

      &-close {
        opacity: .5;

        &:hover {
          opacity: 1;
        }
      }

      &-toolbar {
        padding-top: 10px;
      }
    }
  }
}

.modal-cadastro {
  &-btn {
    &-cadastrar {
      @media (max-width: 599px) {
        display: none;
      }
    }
  }

  &-form {
    .v-card__title {
      padding: 0;
      font-size: 1em;
      font-weight: bold;
    }

    .wrapper-image {
      position: relative;
      .faixa {
        position: absolute;
        bottom: 0;
        background-color: rgba(220, 220, 220, .9);
        display: block;
        width: 100%;
        text-align: center;
        padding: 5px;
        color: #000;
      }
    }
    .percentage {
      input {
        text-align: right;
      }
    }
  }
}

.abas {
  margin-left: 10px;
  .v-tab {
    font-size: 14px;
  }

  .abas-item {
    background-color: transparent;
    border: 1px solid $colorPrimary;
    color: $colorPrimary !important;
    padding-top: 2px;
    
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:before {
      display: none;
    }

    &.v-tab--active {
      background-color: $colorPrimary;
      color: #FFF !important;
    }

  }
  .v-tabs .v-tabs-slider-wrapper {
    display: none;
  }
}

.v-tabs-items {
  margin-top: 15px;
}

