$fontBaseSize: 16px;
$fontCadastroUsuario: 12px;

// Color
$colorPrimary: #1cb4ff;
$colorPrimaryDarken: darken($colorPrimary, 15%);
$colorSecondary: #70d50a;
$colorAccent: #6d7278;
$colorError: #FF5252;
$colorDanger: #FF0000;
$colorInfo: #2196F3;
$colorSuccess: #4CAF50;
$colorWarning: #FFC107;
$colorWrapper: #FFF;
$colorBlueBaby: #d8fdff;
$colorGreenDarken: #008000;
$colorGrayLight: #DDD;
$colorAlert: #e77911;
$colorPixPago: #4B0082;
$colorPositiveGecom: #ff9112;



$colorPrimaryGecom: #54d381;

// FONTES
// $icomoon-font-family: "loto" !default;
// // lotounica font personalizada
// $icomoon-font-path: "~@/assets/fonts" !default;

// $loto-calendario: "\e900";
// $loto-down-arrow: "\e901";
// $loto-icon-expand: "\e902";
// $loto-magnifying-glass: "\e903";

:export {
  colorPrimary: $colorPrimary;
  colorPrimaryDarken: $colorPrimaryDarken;
  colorSecondary: $colorSecondary;
  colorAccent: $colorAccent;
  colorError: $colorError;
  colorDanger: $colorDanger;
  colorInfo: $colorInfo;
  colorSuccess: $colorSuccess;
  colorWarning: $colorWarning;
  colorWrapper: $colorWrapper;
  colorBlueBaby: $colorBlueBaby;
  widthModal: 880px;
  heightModal: 800px;
  colorGreenDarken: $colorGreenDarken;
  colorGrayLight: $colorGrayLight;
  colorPrimaryGecom: $colorPrimaryGecom;
  colorAlert: $colorAlert;
  colorPixPago: $colorPixPago;
  colorPositiveGecom: $colorPositiveGecom;
}
